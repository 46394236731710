import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import decodeHtml from '../../../lib/decode-html'
import isBrowser from '../../../lib/is-browser'
import './index.scss'
import createNestedQuickLinksHeadings from './nested-list'

interface Props {
  headings: MarkdownRemarkHeading[]
}

interface HeadingObserver {
  observer: IntersectionObserver
  target: HTMLElement
}

export default function QuickLinks(props: Props) {
  // Restrict quicklinks to heading levels 2 and 3
  const headings = props.headings.filter(({ depth }) => depth <= 3)

  // Render empty component even if there are no quicklinks to avoid breaking layout
  if (headings.length <= 0) {
    return <div className="QuickLinks"></div>
  }

  const titleId = 'quicklinks-title'
  const pathname = isBrowser() ? location.origin + location.pathname : ''
  const [activeQuickLink, setActiveQuickLink] = useState<string | undefined>(
    headings[0].id
  )
  const quickLinkHeadings = createNestedQuickLinksHeadings(headings, 2)

  // Set up intersection observers for page headings
  useEffect(() => {
    const root = document.querySelector('.Layout')
    if (!root) {
      console.warn(
        'failed to detect observer root - quick links will not update automatically'
      )
      return
    }
    const observers: HeadingObserver[] = []
    const visibleHeadings: string[] = []
    const allHeadings: string[] = headings.reduce((acc: string[], { id }) => {
      acc.push(id)
      return acc
    }, [])
    const getOrderedVisibleHeadings = (): string[] => {
      return allHeadings.filter((heading) => visibleHeadings.includes(heading))
    }

    headings.forEach(({ id }) => {
      const target = document.getElementById(id)
      if (!target) {
        return
      }
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target !== target) {
              return
            }
            if (entry.isIntersecting) {
              visibleHeadings.push(id)
            } else if (visibleHeadings.includes(id)) {
              visibleHeadings.splice(visibleHeadings.indexOf(id), 1)
            }
            const orderedVisibleHeadings = getOrderedVisibleHeadings()
            if (orderedVisibleHeadings.length) {
              setActiveQuickLink(orderedVisibleHeadings[0])
            }
          })
        },
        { root, rootMargin: '-70px 0px 0px 0px' }
      )
      observer.observe(target)
      observers.push({ observer, target })
    })
    return () => {
      observers.forEach(({ observer, target }) => observer.unobserve(target))
    }
  }, [])

  const handleLinkClick = (id: string) => {
    if (!isBrowser()) {
      return
    }
    // Defer state update until render is finished to ensure this update supercedes that of the intersection observer.
    // Necessary when two headings are close together in content and navigating to one causes the other's intersection observer to trigger.
    setTimeout(() => setActiveQuickLink(id), 10)
  }

  const Item = ({ id, value, children }: QuickLinksHeading) => {
    const title = (isBrowser() ? decodeHtml(value) : value)
      // Remove the wrapping tags added by gatsby-remark-prismjs
      // Relevant when the heading contains an inline code snippet (ex: "## `methodName()`")
      .replace('<code class="gatsby-code-text">', '')
      .replace('</code>', '')
    const isActive = id === activeQuickLink
    return (
      <li key={id}>
        <a
          className={classNames({ active: isActive })}
          href={`${pathname}#${id}`}
          onClick={() => handleLinkClick(id)}
        >
          <span>{title}</span>
        </a>
        {children.length > 0 && (
          <ol>
            {children.map((heading: QuickLinksHeading) => (
              <Item
                key={heading.id}
                id={heading.id}
                value={heading.value}
                children={heading.children}
              />
            ))}
          </ol>
        )}
      </li>
    )
  }

  return (
    <nav className="QuickLinks" aria-labelledby={titleId}>
      <div className="QuickLinks__Sticky">
        <div className="QuickLinks__Title" id={titleId}>
          <h2>Quick page links</h2>
        </div>
        <ol>
          {quickLinkHeadings.map(({ id, value, children }) => {
            return <Item key={id} id={id} value={value} children={children} />
          })}
        </ol>
      </div>
    </nav>
  )
}
