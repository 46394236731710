/**
 * Convert a flat array of headings, as provided by MarkdownRemark nodes, into a nested array of headings.
 * This nested structure is used to render the nested Quick Links list.
 */

export default function createNestedQuickLinksHeadings(
  headings: MarkdownRemarkHeading[],
  depth: number
): QuickLinksHeading[] {
  const result: QuickLinksHeading[] = []

  for (const heading of headings) {
    // Only process headings of the current depth
    if (heading.depth !== depth) {
      continue
    }
    // Find all of the child headings, up until the next sibling of the same depth
    const subsequentHeadings = headings.slice(headings.indexOf(heading) + 1)
    const nextSiblingIndex = subsequentHeadings.findIndex(
      (item) => item.depth === depth
    )
    let children: MarkdownRemarkHeading[] = []
    switch (nextSiblingIndex) {
      case -1:
        children = subsequentHeadings
        break
      case 0:
        children = []
        break
      default:
        children = subsequentHeadings.slice(0, nextSiblingIndex)
        break
    }

    result.push({
      id: heading.id,
      value: heading.value,
      children: createNestedQuickLinksHeadings(children, depth + 1),
    })
  }

  return result
}
