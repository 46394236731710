import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import '@deque/cauldron-styles'
import { useGatsbyPageContext } from '../../context/GatsbyPageContext'
import { pageView as sendPageViewEvent } from '../../lib/analytics'
import ArticleContent from './ArticleContent'

interface Props {
  data: ContentPageData
}

export default function ContentPage(props: Props) {
  const { data } = props
  const { productId, version, locale, pageId } = useGatsbyPageContext()

  useEffect(() => {
    sendPageViewEvent({
      productId,
      version,
      locale,
      pageId,
    })
  }, [productId, version, locale, pageId])

  return (
    <ArticleContent headings={data.markdownRemark.headings}>
      <div
        dangerouslySetInnerHTML={{
          __html: data.markdownRemark.html,
        }}
      />
    </ArticleContent>
  )
}

export const pageQuery = graphql`
  query ContentPage(
    $productId: String
    $version: String
    $locale: String
    $pageId: String
  ) {
    markdownRemark(
      fields: {
        productId: { eq: $productId }
        version: { eq: $version }
        locale: { eq: $locale }
        pageId: { eq: $pageId }
      }
    ) {
      html
      headings {
        id
        value
        depth
      }
    }
  }
`
