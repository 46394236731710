import React, { useEffect } from 'react'
import QuickLinks from '../QuickLinks'
import './index.scss'

interface ArticleContentProps {
  children: React.ReactNode
  headings: MarkdownRemarkHeading[]
}

export default function ArticleContent(props: ArticleContentProps) {
  const { children, headings } = props
  const canvasRef = React.useRef<HTMLDivElement>(null)

  // Add tabindex to all tables within the content canvas to make them focusable.
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) {
      return
    }
    const tables = canvas.querySelectorAll('table')
    tables.forEach((table) => {
      table.setAttribute('tabindex', '0')
    })
  }, [canvasRef])

  return (
    <div className="Article__Content">
      <QuickLinks headings={headings} />
      <div className="Article__ContentCanvas" ref={canvasRef}>
        {children}
      </div>
    </div>
  )
}
